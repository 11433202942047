<template>
  <div class="customers p-8 mx-auto max-w-5xl">
    <div class="max-w-4xl space-y-6">
      <div class="flex justify-between pb-2 items-center">
        <h3 class="text-3xl font-semibold">
          {{ customer.customer_name }}
        </h3>
        <router-link
          :to="`${route.path}/edit`"
          class="hover-trigger flex align-middle gap-1 text-blue-600 hover:text-blue-700"
        >
          <span class="hover-target pt-1 font-medium">Edit</span>
          <svg
            class="h-7 w-7"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
            />
          </svg>
        </router-link>
      </div>

      <div v-if="isLoading" class="flex justify-center gap-1">
        <div class="spinner dark"></div>
        <div>Loading</div>
      </div>

      <div class="shadow overflow-hidden border border-gray-200">
        <table class="w-full divide-y divide-gray-200">
          <tbody>
            <tr class="hover:bg-gray-50 border-b">
              <td
                class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 uppercase bg-gray-100 border-r align-top"
              >
                Business Name
              </td>
              <td class="px-6 py-4 text-sm text-gray-500 w-full">
                {{ customer.billing_business }}
              </td>
            </tr>

            <tr class="hover:bg-gray-50 border-b">
              <td
                class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 uppercase bg-gray-100 border-r align-top"
              >
                Name
              </td>
              <td class="px-6 py-4 text-sm text-gray-500">
                {{ customer.customer_name }}
              </td>
            </tr>
            <tr class="hover:bg-gray-50 border-b">
              <td
                class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 uppercase bg-gray-100 border-r align-top"
              >
                Phone
              </td>
              <td class="px-6 py-4 text-sm text-gray-500">
                {{ customer.customer_phone }}
              </td>
            </tr>
            <tr class="hover:bg-gray-50 border-b">
              <td
                class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 uppercase bg-gray-100 border-r align-top"
              >
                Email
              </td>
              <td class="px-6 py-4 text-sm text-gray-500">
                {{ customer.customer_email }}
              </td>
            </tr>

            <tr class="hover:bg-gray-50 border-b">
              <td
                class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 uppercase bg-gray-100 border-r align-top"
              >
                Customer Type
              </td>
              <td class="px-6 py-4 text-sm text-gray-500">
                {{ customerTypeFromId(customer?.customer_type) }}
              </td>
            </tr>

            <tr class="hover:bg-gray-50">
              <td
                class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 uppercase bg-gray-100 border-r align-top"
              >
                <div>Customer Notes</div>
                <div>(Internal Use)</div>
              </td>
              <td class="px-6 py-4 text-sm text-gray-500 whitespace-pre-wrap">
                {{ customer.customer_notes }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="shadow overflow-hidden border border-gray-200">
        <table class="w-full divide-y divide-gray-200">
          <tbody>
            <tr class="hover:bg-gray-50 border-b">
              <td
                class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 uppercase bg-gray-100 border-r align-top"
              >
                Billing Phone
              </td>
              <td class="px-6 py-4 text-sm text-gray-500 w-full">
                {{ customer.billing_phone }}
              </td>
            </tr>
            <tr class="hover:bg-gray-50 border-b">
              <td
                class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 uppercase bg-gray-100 border-r align-top"
              >
                Billing Address
              </td>
              <td class="px-6 py-4 text-sm text-gray-500">
                <span class="block">{{ customer.billing_address1 }}</span>
                <span v-if="customer.billing_address2" class="block">{{
                  customer.billing_address2
                }}</span>
                <span class="block"
                  >{{ customer.billing_city }} {{ customer.billing_state }}
                  {{ customer.billing_zip }}</span
                >
              </td>
            </tr>
            <tr class="hover:bg-gray-50 border-b">
              <td
                class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 uppercase bg-gray-100 border-r align-top"
              >
                Status
              </td>
              <td class="px-6 py-4 text-sm text-gray-500">
                {{ customer.customer_status }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="flex justify-between">
        <h3 class="text-xl font-semibold ">
          Locations
        </h3>
        <div
          @click="addLocation()"
          class="hover-trigger flex align-middle gap-1 text-blue-600 hover:text-blue-700 cursor-pointer"
        >
          <span class="hover-target pt-1 font-medium">Add Location</span>
          <svg
            class="h-7 w-7 text-blue-600 hover:text-blue-700"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </div>
      </div>

      <div
        v-for="location in filteredLocations"
        :key="location.id"
        class="shadow overflow-hidden border border-gray-200"
      >
        <table class="w-full divide-y divide-gray-200">
          <thead class="text-left bg-gray-100 font-medium">
            <tr class="border-b text-sm text-gray-900 hover:text-gray-600">
              <th colspan="6">
                <table>
                  <tr>
                    <td
                      @click="goTo(`/locations/${location.id}`)"
                      class="px-6 py-4 cursor-pointer"
                    >
                      <div>{{ location.location_address1 }}</div>
                      <div v-if="location.location_address2">
                        {{ location.location_address2 }}
                      </div>
                      <div>
                        {{ location.location_city }}
                        {{ location.location_state }}
                        {{ location.location_zip }}
                      </div>
                      <div>{{ location.location_notes }}</div>
                    </td>
                    <td
                      @click="
                        locationMap(
                          `${location.location_address1}, ` +
                            (location.location_address2
                              ? `${location.location_address2}, `
                              : '') +
                            `${location.location_city} ${location.location_state} ${location.location_zip}`
                        )
                      "
                      class="px-6 py-4 cursor-pointer"
                    >
                      <p>map</p>
                    </td>
                  </tr>
                </table>
              </th>
            </tr>
            <tr class="text-xs text-gray-500 uppercase">
              <th scope="col" class="px-6 py-3 tracking-wider">
                Status
              </th>
              <th scope="col" class="px-6 py-3 tracking-wider">
                Number
              </th>
              <th scope="col" class="px-6 py-3 tracking-wider">
                Created
              </th>
              <th scope="col" class="px-6 py-3 tracking-wider">
                Total Price
              </th>
              <th scope="col" class="px-6 py-3 tracking-wider">
                Assigned To
              </th>
              <th scope="col" class="px-6 py-3 tracking-wider">
                Accepted
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-for="quote in quotes" :key="quote.id">
              <tr
                v-if="location.id == quote.location_id"
                class="text-sm text-gray-500 hover:bg-gray-50 border-b cursor-pointer"
                @click="goTo(`/quotes/${quote.id}`)"
              >
                <td class="px-6 py-4 whitespace-nowrap">
                  <span
                    :class="{
                      'px-2 rounded-full bg-green-500 text-white':
                        quote.quote_status == 'pending',
                      'px-2 rounded-full bg-blue-500 text-white':
                        quote.quote_status == 'assigned',
                      'px-2 rounded-full bg-purple-500 text-white':
                        quote.quote_status == 'processed',
                      'px-2 rounded-full bg-gray-500 text-white':
                        quote.quote_status == 'emailed',
                      'px-2 rounded-full bg-black text-white':
                        quote.quote_status == 'completed',
                    }"
                    class="font-semibold capitalize"
                  >
                    {{ quote.quote_status }}
                  </span>
                </td>
                <td class="px-6 py-4 whitespace-nowrap">
                  {{ quote.sequence_number }}
                </td>

                <td class="px-6 py-4 whitespace-nowrap">
                  {{ fromSeconds(quote.createdAt?.seconds) }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap">
                  {{
                    quote.override_price
                      ? quote.override_price
                      : quote.total_price
                  }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap">
                  {{ usernameFromId(quote.assigned_to) }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap">
                  {{ quote.accepted ? "&check;" : "" }}
                </td>
              </tr>
            </template>
            <tr
              @click="goToAddQuote(location, customer)"
              class="text-sm text-gray-500 hover:bg-gray-50 hover:text-blue-500"
            >
              <td colspan="6" class="px-6 py-4 cursor-pointer">
                Create new quote
                <span class="font-semibold text-blue-500">&gt;</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div>
        <hr class="border-b border-blue-500" />
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref, watchEffect } from "vue";
import { useRoute, useRouter } from "vue-router";
import getDocument from "@/composables/getDocument";
import getCollection from "@/composables/getCollection";
import useLuxon from "@/composables/useLuxon";

export default {
  props: ["customer_id", "user"],
  emits: ["loadModalMap"],
  setup(props, context) {
    const isLoading = ref(true);
    const route = useRoute();
    const router = useRouter();

    const business_id = props.user.claims.business_id ?? "";

    const { error: customer_err, document: customer } = getDocument(
      `businesses/${business_id}/customers`,
      props.customer_id
    );

    const { error: locations_err, documents: locations } = getCollection(
      `businesses/${business_id}/locations`
    );

    const {
      error: customertypes_err,
      documents: customertypes,
    } = getCollection(`businesses/${business_id}/customer_types`);

    const { error: quotes_err, documents: quotes } = getCollection(
      `businesses/${business_id}/quotes`
    );

    const customerTypeFromId = (customertype_id) => {
      if (customertypes?.value) {
        if (!customertype_id) return;
        return customertypes.value?.find(({ id }) => id === customertype_id)
          .customertype_name;
      }
    };

    const filteredLocations = computed(() => {
      return locations.value?.filter(
        (l) =>
          l.location_status == "active" &&
          customer.value.locations?.includes(l.id)
      );
    });

    const filteredQuotes = computed(() => {
      return quotes.value?.filter((q) => q.customer_id == customer.value.id);
    });

    const { error: users_err, documents: users } = getCollection(
      `businesses/${business_id}/users`
    );

    watchEffect(() => {
      if (customer.value && locations.value && quotes.value && users.value) {
        isLoading.value = false;
      }
    });

    const usernameFromId = (id) => {
      if (id && users.value) {
        const assigned = users.value.filter((u) => u.id == id).shift();
        return assigned.user_name;
      }
      return;
    };

    const addLocation = () => {
      router.push({
        name: "LocationsAdd",
        params: { customer_id: customer.value.id },
      });
    };

    const goTo = (url) => {
      router.push(url);
    };

    const goToAddQuote = (location, customer) => {
      router.push(`/quotes/add/${customer.id}/${location.id}`);
    };

    const { fromSeconds } = useLuxon();

    const locationMap = (data) => {
      context.emit("loadModalMap", data);
    };

    return {
      isLoading,
      route,
      customer_err,
      customer,
      locations_err,
      locations,
      quotes_err,
      quotes,
      filteredLocations,
      filteredQuotes,
      users_err,
      customertypes_err,
      users,
      usernameFromId,
      addLocation,
      goTo,
      goToAddQuote,
      fromSeconds,
      customerTypeFromId,
      locationMap,
    };
  },
};
</script>
